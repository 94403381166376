import React from "react";
import { Container } from "react-bootstrap";
import Section from "../components/Section";
import PageWrapper from "../components/PageWrapper";



const NotFoundPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container style={{minHeight: 700}}>
            <div className="text-center">
            <p style={{fontSize: 17}}>Step 2 / 2</p>
  <h1 style={{fontSize: 40, fontWeight: 300, marginTop: 0}}>Welcome to FounderAlert <span role="img" aria-label="fire">🎉</span></h1>
<h1 style={{fontSize: 60, fontWeight: 700, marginTop: 0}}>What happens now?</h1>
<h3 style={{fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>1 - Confirm your email <span role="img" aria-label="fire">✉️</span></h3>
<p style={{maxWidth: 700, textAlign: 'center', margin: 'auto'}}>You will receive a first email from <a href="mailto:john@founderalert.com">john@founderalert.com</a> with your confirmation link (you may need to check your spam box).</p>
<h3 style={{ fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>2 - Get your first list of 69 business ideas  <span role="img" aria-label="business ideas">💡</span></h3>
<p style={{maxWidth: 700, textAlign: 'center', margin: 'auto'}}>A second email will arrive with the business ideas from the previous week.</p>
<h3 style={{ fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>3 - See you on Tuesday <span role="img" aria-label="newsletter">⏰</span></h3>
<p>Every Tuesday morning, the latest list of business ideas will be in your inbox</p>
<p>For any questions, please don't hesitate to contact us at <a href="mailto:support@founderalert.com">support@founderalert.com</a></p>
      
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default NotFoundPage;
